// 查询列表
const queryListURL = `${API_CONFIG.controlBaseURL}tenant/list`
// 删除
const deleteURL = `${API_CONFIG.controlBaseURL}tenant/delete/`
// tree
const getRegionTreeURL = `${API_CONFIG.butlerBaseURL}region/getRegionTree`

// 查询详情
const queryDetailURL = `${API_CONFIG.controlBaseURL}tenant/get/`
// 新增
const createURL = `${API_CONFIG.controlBaseURL}tenant/add`
// 编辑
const updateURL = `${API_CONFIG.controlBaseURL}tenant/update`
// 所属渠道 select2
const queryChannelURL = `${API_CONFIG.controlBaseURL}tenant/channel/select2`

// 查询列表 - 产品域 select2
const queryProductRegionListURL = `${API_CONFIG.controlBaseURL}product/region/select2`
// 查询详情 - 产品域
const queryProductRegionDetailURL = `${API_CONFIG.controlBaseURL}product/region/detail`
// 关联产品域
const attachProductRegionURL = `${API_CONFIG.controlBaseURL}product/region/attach/tenant`

// 查询列表 -业务域 select2
const queryBusinessDomainListURL = `${API_CONFIG.controlBaseURL}businessRegion/select2`
// 查询详情 - 业务域
const queryBusinessDomainDetailURL = `${API_CONFIG.controlBaseURL}businessRegion/`
// 关联业务域
const attachBusinessDomainURL = `${API_CONFIG.controlBaseURL}businessRegion/attachTenant`

// 选择角色
const getRolesURL = `${API_CONFIG.controlBaseURL}sysRole/select2Roles`
// 获取租户通用角色
const getTenantCommonRoleURL = `${API_CONFIG.controlBaseURL}sysRole/getTenantCommonRole`

export {
  queryListURL,
  deleteURL,
  getRegionTreeURL,
  queryDetailURL,
  createURL,
  updateURL,
  queryChannelURL,
  queryProductRegionListURL,
  queryProductRegionDetailURL,
  attachProductRegionURL,
  queryBusinessDomainListURL,
  queryBusinessDomainDetailURL,
  attachBusinessDomainURL,
  getRolesURL,
  getTenantCommonRoleURL
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TenantList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          extraParams: _vm.extraParams,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tableLeftSlot",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "tree-group" },
                  [
                    _c("v-tree", {
                      attrs: {
                        data: _vm.treeData,
                        defaultProps: _vm.treeProps,
                        lazy: true,
                        "expand-on-click-node": false,
                      },
                      on: { loadData: _vm.treeLoad, nodeClick: _vm.selectNode },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "公司名称" },
                  model: {
                    value: _vm.searchParams.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "name", $$v)
                    },
                    expression: "searchParams.name",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "创建人" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间段",
                    type: "rangedatetimer",
                    maxDate: new Date(),
                    startTime: _vm.searchParams.createTimeFrom,
                    endTime: _vm.searchParams.createTimeTo,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createTimeFrom",
                        $event
                      )
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(
                        _vm.searchParams,
                        "createTimeFrom",
                        $event
                      )
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "createTimeTo", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "createTimeTo", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.update(scope.row)
                    },
                  },
                }),
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "删除",
                        type: "text",
                        permission: "delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteClick(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 1
                  ? _c("v-button", {
                      attrs: {
                        text: "查看项目",
                        type: "text",
                        permission: "viewCommunity",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.viewCommunityClick(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                _c("v-button", {
                  attrs: {
                    text: "编辑菜单",
                    type: "text",
                    permission: "updateMenu",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.editMenu(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="TenantFormBase-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <template slot="headerSlot">
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail>
        <col2-block>
          <!-- <el-form-item label="所属渠道" :rules="[{ required: true, message: '请选择所属渠道', trigger: 'change' }]" prop="tenantChannelId">
            <v-select2 class="select2-channel" v-model="form.tenantChannelId" placeholder="输入渠道名称" v-bind="tenantChannelParams" :width="width" />
          </el-form-item> -->
          <el-form-item label="所属渠道" :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]">
            <v-input v-model="form.tenantChannelName" placeholder="请输入所属渠道" :maxlength="36" :width="width" :disabled="true"/>
          </el-form-item>
          <el-form-item label="可管项目数" :rules="[{ required: true, message: '请输入可管项目数', trigger: 'blur' }]" prop="communityMaxNum">
            <v-input-number v-model="form.communityMaxNum" placeholder="请输入数字" :max="9999" controls controlsPosition="right" :width="width" />
            <label class="tips">个</label>
          </el-form-item>
          <el-form-item label="授权有效期" :rules="[{ required: true, message: '请输入授权有效期', trigger: 'blur' }]" prop="termOfValidity">
            <span class="register">注册生效后</span>
            <v-input-number v-model="form.termOfValidity" placeholder="请输入授权有效期" :max="9999" controls controlsPosition="right" :width="width" />
            <label class="tips">个月</label>
          </el-form-item>
          <el-form-item label="产品域" :rules="[{ required: true, message: '请选择产品域名称', trigger: 'change' }]" prop="productRegion">
            <v-select2 v-model="form.productRegion" placeholder="查询产品域名称" v-bind="productRegionParams" @onChange="productRegionChange" :width="width" />
          </el-form-item>
          <el-form-item label="业务域" :rules="[{ required: true, message: '请选择业务域名称', trigger: 'change' }]" prop="businessRegion">
            <v-select2 v-model="form.businessRegion" placeholder="查询业务域名称" v-bind="businessDomainParams" @onChange="businessDomainChange" :width="width" />
          </el-form-item>
          <el-form-item
            label="管理员角色"
            prop="roleId"
            :rules="[
              { required: true, message: '请选择管理员角色', trigger: 'change' },
            ]"
          >
            <v-select2
              v-model="form.roleId"
              :subjoin="rolesExtraParams"
              v-bind="roleParams"
              placeholder="查询角色权限"
              :width="width"
              @onChange="roleChange"
              :handleData="handleData"
            ></v-select2>
          </el-form-item>
          <el-form-item label="授权应用" :rules="[{ required: true, message: '请选择授权应用', trigger: 'blur' }]" prop="applicationInfo">
            <chosen-list-panel :list.sync="form.applicationInfo" textAs="subName" @select="showMultiSelect = true" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>

    <multi-select
      title="应用列表"
      :isShow.sync="showMultiSelect"
      :searchUrl="getApplicationListURL"
      :headers="headers"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.applicationInfo">
        <template #searchSlot>
          <v-input label="应用名称" v-model="searchParams.subName" />
          <v-select label="应用类别" v-model="searchParams.subHandle" :options="subHandleOps" />
          <v-select label="应用类型" v-model="searchParams.subType" :options="subTypeOps" />
          <v-input label="应用描述" v-model="searchParams.subNote" />
        </template>
    </multi-select>
  </div>
</template>

<script>
import { Col2Detail, Col2Block, ChosenListPanel,MultiSelect } from 'components/bussiness'
import { queryDetailURL, updateURL, queryChannelURL,queryProductRegionListURL,queryBusinessDomainListURL,getApplicationListURL,getRolesURL} from './api'
import { subHandleMap, setSubHandleOps, subTypeMap, setSubTypeOps, appStatusMap } from './map'

export default {
  name: 'TenantFormBase',
  components: {
    Col2Detail,
    Col2Block,
    ChosenListPanel,
    MultiSelect
  },
  data () {
    return {
      width: 182,
      tenantChannelParams: {
        searchUrl: queryChannelURL
      },
      form: {
        id: '',
        communityMaxNum: '',
        termOfValidity: '',
        productRegion: undefined,
        businessRegion: undefined,
        applicationInfo: [],
        roleId: '',
        roleName: '',
        userId: '',
        tenantChannelId: '',
        tenantChannelName: '',
        roleName: ''
      },
      submitConfig: {
        queryUrl: queryDetailURL,
        submitUrl: updateURL,
        submitMethod: 'POST'
      },
      productRegionParams: {
        searchUrl: queryProductRegionListURL
      },
      businessDomainParams: {
        searchUrl: queryBusinessDomainListURL
      },
      subHandleOps: setSubHandleOps(1),
      subTypeOps: setSubTypeOps(1),
      showMultiSelect: false,
      getApplicationListURL,
      headers: [
        {
          prop: 'subName',
          label: '应用名称'
        },
        {
          prop: 'subHandleText',
          label: '应用类别',
          formatter: row => subHandleMap[row.subHandle]
        },
        {
          prop: 'subTypeText',
          label: '应用类型',
          formatter: row => subTypeMap[row.subType]
        },
        {
          prop: 'subStatusText',
          label: '应用状态',
          formatter: row => appStatusMap[row.subStatus]
        },
        {
          prop: 'subNote',
          label: '应用描述'
        }
      ],
      searchParams: {
        subName: '',
        subHandle: undefined,
        subType: undefined,
        subNote: ''
      },
      responseParams: {
        id: 'id',
        name: 'subName'
      },
      responseKey: {
        id: 'id',
        name: 'subName'
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
    }
  },
  mounted () {
    this.$refs.formPanel.getData()
  },
  computed: {
    rolesExtraParams () {
      return {
        roleType: 102
      }
    },
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.form.applicationInfo = data.getRegionApplication.map((item) => ({ id: item.id, subName: item.name }))
    },
    submitBefore (data) {
      data.applicationInfo = data.applicationInfo.map(item => ({ id: item.id, name: item.subName }))
      return true
    },
    productRegionChange (productRegion) {
      //产品域修改
    },
    businessDomainChange (businessDomain) {

    },
    roleChange (val) {
      if(val){
        this.form.roleName = val.roleName
      }
    },
    handleData (res) {
      let newData = res.map(item => {
        return {
          name : item.name + "-" + item.orgName,
          id : item.id,
          roleName: item.name
        }
      })
      return newData
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantFormBase-wrapper {
  height: 100%;
  .tips {
    margin-left: 20px;
  }
  .select2-channel {
    display: inline-block;
  }
  .register{
    margin-right: 20px;
  }
}
</style>

<template>
  <div class="TenantList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :extraParams="extraParams"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #tableLeftSlot>
        <div class="tree-group">
          <v-tree
            :data="treeData"
            :defaultProps="treeProps"
            :lazy="true"
            :expand-on-click-node="false"
            @loadData="treeLoad"
            @nodeClick="selectNode"
          ></v-tree>
        </div>
      </template>
      <template #searchSlot>
        <v-input label="公司名称" v-model="searchParams.name" />
        <v-input label="创建人" v-model="searchParams.userName" />
        <v-datepicker label="创建时间段" type="rangedatetimer" :maxDate="new Date()" :startTime.sync="searchParams.createTimeFrom" :endTime.sync="searchParams.createTimeTo" />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="update(scope.row)" />
        <v-button v-if="scope.row.status === 0" text="删除" type="text" permission="delete" @click="deleteClick(scope.row)" />
        <v-button v-if="scope.row.status === 1" text="查看项目" type="text" permission="viewCommunity" @click="viewCommunityClick(scope.row)" />
        <v-button text="编辑菜单" type="text" permission="updateMenu" @click="editMenu(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getCommunityListURL } from 'common/api'
import { queryListURL, deleteURL, getRegionTreeURL } from './api'
import { statusMap } from './map'
import { vTree } from 'components/control'

export default {
  name: 'TenantList',
  components: {
    vTree
  },
  data () {
    return {
      searchUrl: queryListURL,
      extraParams: {},
      searchParams: {
        name: '',
        userName: '',
        createTimeFrom: '',
        createTimeTo: ''
      },
      headers: [
        {
          prop: 'name',
          label: '公司名称'
        },
        {
          prop: 'status',
          label: '状态',
          formatter: row => statusMap[row.status]
        },
        {
          prop: 'tenantChannelName',
          label: '所属渠道'
        },
        {
          prop: 'userName',
          label: '创建人'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ],
      treeData: [],
      treeProps: {
        children: 'children',
        label: 'name',
        isLeaf: (data, node) => {
          return !data.isParent
        }
      },
    }
  },
  created () {
    const tenantChannelId = this.$route.query.tenantChannelId
    if (tenantChannelId) {
      this.extraParams.tenantChannelId = tenantChannelId
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'tenantForm'
      })
    },
    update (row) {
      this.$router.push({
        name: 'tenantForm',
        query: {
          tenantId: row.id
        }
      })
    },
    editMenu (row) {
      // 编辑菜单
      this.$router.push({
        name: 'menuFormTree',
        query: {
          tenantId: row.id
        }
      })
    },
    async deleteClick (row) {
      let result = await this.$confirm('确认删除？')
      if (result) {
        const url = `${deleteURL}${row.id}`
        const { status, data } = await this.$axios.delete(url)
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },
    async viewCommunityClick (row) {
      const params = {
        regionId: row.id
      }
      const { status, data } = await this.$axios.get(getCommunityListURL, { params })
      if (status === 100) {
        if (data && data.length) {
          const projectList = []
          data.forEach(item => {
            projectList.push(item.name)
          })
          const projectListStr = projectList.join(',')
          this.$alert(projectListStr, {
            title: `${row.name} -- 查看项目`,
            closeOnClickModal: true,
            showConfirmButton: false,
            showClose: true
          })
        } else {
          this.$message.info('暂未关联项目，请尽快完成关联操作')
        }
      }
    },
    getProjectTree (level, id) {
      let params = {
        nodeLevel: level
      }
      id && (params.id = id)
      return new Promise((resolve, reject) => {
        this.$axios.get(getRegionTreeURL, { params }).then(res => {
          if (res.status === 100) {
            let { data } = res
            resolve(data)
          }
        })
      })
    },
    async treeLoad (node, resolve) {
      let { nodeLevel, id } = node.data
      let list = await this.getProjectTree(nodeLevel, id)
      setTimeout(() => {
        return resolve(list)
      }, 500)
    },
    selectNode (data) {
      let { name, nodeLevel } = data
      this.$nextTick(() => {
        if (nodeLevel === 0) {
          this.$set(this.searchParams, 'name', name)
          this.$refs.list.searchData()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.TenantList-wrapper {
  .tree-group {
    width: 240px;
    height: 100%;
    padding: 10px;
    overflow: auto;
    box-sizing: border-box;
    text-align: left;
  }
}
</style>
